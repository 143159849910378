import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import store from '@/store/index';

Vue.use(VueRouter);
/**
 * 跳转路由
 * @param  {String}  path      跳转地址
 * @param  {String}  type      类型
 * @param  {Object}  obj       需要传递的参数 eg： {id：1，title：name}
 */
VueRouter.prototype.jumpRoute = function (path, obj, type) {
    let types = type || 'push';
    //判断是否客服链接
    if(path == store.state.servicerUrl){
        this[types]({
            path: '/customer/main',
        });
        return false;
    }
    if (path) {
        //判断是否外链
        if (path.indexOf('http') != -1) {
            window.open(path,'_self');
            // window.open(path, '_blank');
            // setTimeout(() => {
            //     let tempALink = document.createElement("a");
            //     tempALink.setAttribute("target", "_blank");
            //     tempALink.setAttribute("id", "openWin");
            //     tempALink.setAttribute("href", path);
            //     document.body.appendChild(tempALink);
            //     tempALink.click();
            //     setTimeout(() => {
            //         document.body.removeChild(tempALink);
            //     }, 1500);
            // }, 100);
        } else {
            this[types]({
                path: path,
                query: { ...(obj || {}) },
            });
        }
    } else {
        this.go(-1);
    }
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/entry/login', //登录
        name: 'Login',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/entry/login.vue'),
    },
    {
        path: '/entry/register', //注册
        name: 'Login',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/entry/register.vue'),
    },
    {
        path: '/activity/list', //优惠列表
        name: 'List',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/activity/list.vue'),
    },
    {
        path: '/activity/activityDetail/:id', //优惠详情
        name: 'ActivityDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/activity/activityDetail.vue'),
    },
    {
        path: '/activity/record/:id', //优惠申请记录
        name: 'Record',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/activity/record.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/customer/main', //客服
        name: 'Main',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/customer/main.vue'),
    },
    {
        path: '/sponsor', //赞助
        name: 'Sponsor',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/sponsor/sponsor.vue'),
    },
    {
        path: '/sponsor/sponsorDetail1', //赞助详情
        name: 'SponsorDetail1',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/sponsor/sponsorDetail1.vue'),
    },
    {
        path: '/mine', //我的
        name: 'Mine',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/index.vue'),
    },
    {
        path: '/mine/mail', //消息中心
        name: 'Mail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/mail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/vip/privilege', //VIP特权
        name: 'Privilege',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/vip/privilege.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/vip/detail', //VIP详情
        name: 'VipDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/vip/vipDetail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/welfare', //福利中心
        name: 'Welfare',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/welfare.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/about', //关于我们
        name: 'About',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/about.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/activity/bettingGift', //首存豪礼
        name: 'BettingGift',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/activity/bettingGift.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/agentPage', //加入我们
        name: 'AgentPage',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/agentPage.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/helpCenter', //帮助中心
        name: 'HelpCenter',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/helpCenter.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/feedback', //意见反馈
        name: 'Feedback',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/feedback.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/activity/friendInvitation', //邀请好友
        name: 'FriendInvitation',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/activity/friendInvitation.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/cards', //账户管理
        name: 'Cards',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/cards/cards.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/bankDetail', //账户详情
        name: 'BankDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/cards/bankDetail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/virtualDetail', //虚拟币详情
        name: 'VirtualDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/cards/virtualDetail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/cards/addBankCards', //添加银行卡
        name: 'AddBankCards',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/cards/addBankCards.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/cards/addVirtualAccount', //添加虚拟币
        name: 'AddVirtualAccount',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/cards/addVirtualAccount.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/cards/addPayAccount', //添加其他虚拟币
        name: 'AddPayAccount',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/cards/addPayAccount.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/transaciton', //交易记录
        name: 'Transaciton',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/transaciton.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/transacitonDetail', //交易详情
        name: 'TransacitonDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/transacitonDetail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/bet', //投注记录
        name: 'Bet',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/bet.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/betDetail', //投注详情
        name: 'BetDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/betDetail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/reward', //兑奖记录
        name: 'Reward',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/reward.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/record/rewardDetail', //兑奖记录
        name: 'RewardDetail',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/record/rewardDetail.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/finance/transfer', //转账
        name: 'Transfer',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/transfer.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/finance/transferCourse', //转账教程
        name: 'TransferCourse',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/transferCourse.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/finance/withDraw', //取款
        name: 'WithDraw',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/withDraw.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/finance/myDeposit', //存款
        name: 'MyDeposit',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/myDeposit.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/finance/payOrder', //支付页面
        name: 'PayOrder',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/payOrder.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/finance/payMent', //支付页面
        name: 'PayMent',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/payMent.vue'),
    },
    {
        path: '/mine/finance/knowVirtualCurrency', //了解虚拟币
        name: 'KnowVirtualCurrency',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/knowVirtualCurrency.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/system', //设置
        name: 'System',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/system.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/mine/verificationPhone', //修改密码
        name: 'VerificationPhone',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/userInfo/verificationPhone.vue'),
    },
    {
        path:'/download',
        name:"Download",
        component: () => import(/*webpackChunkName:"user"*/ '@/views/download.vue'),
    }
    // {
    //     path: '/game/sport', //体育
    //     name: 'Sport',
    //     component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Sport.vue'),
    // },
    // {
    //     path: '/game/realbet', //真人
    //     name: 'Realbet',
    //     component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Realbet.vue'),
    // },
    // {
    //     path: '/game/joker', //棋牌
    //     name: 'Joker',
    //     component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Joker.vue'),
    // },
    // {
    //     path: '/game/gaming', //电竞
    //     name: 'Gaming',
    //     component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Gaming.vue'),
    // },
    // {
    //     path: '/game/lottery', //彩票
    //     name: 'Lottery',
    //     component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Lottery.vue'),
    // },
    // {
    //     path: '/game/concise', //电子
    //     name: 'Concise',
    //     component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Concise.vue'),
    // },
    // {
    //     path: '/agent', //合营
    //     name: 'Agent',
    //     component: () => import(/*webpackChunkName:"operate"*/ '@/views/navUse/Agent.vue'),
    //     meta: {
    //         layout: 'noFooter',
    //     },
    // },
    // {
    //     path: '/activity/discount', //优惠
    //     name: 'Discount',
    //     component: () => import(/*webpackChunkName:"operate"*/ '@/views/activity/Discount.vue'),
    // },
    // {
    //     path: '/activity/activityLandPage/:id', //高额返水
    //     name: 'ActivityLandPage',
    //     component: () => import(/*webpackChunkName:"operate"*/ '@/views/activity/ActivityLandPage.vue'),
    // },
    // {
    //     path: '/activity/bettingGift', //豪礼盛宴
    //     name: 'BettingGift',
    //     component: () => import(/*webpackChunkName:"operate"*/ '@/views/activity/BettingGift.vue'),
    // },
    // {
    //     path: '/sponsor/rmcf', //赞助
    //     name: 'Rmcf',
    //     component: () => import(/*webpackChunkName:"operate"*/ '@/views/navUse/sponsor/Rmcf.vue'),
    //     meta: {
    //         layout: 'noFooter',
    //     },
    // },
    // {
    //     path: '/download', //APP下载
    //     name: 'Download',
    //     component: () => import(/*webpackChunkName:"operate"*/ '@/views/navUse/Download.vue'),
    //     meta: {
    //         layout: 'noFooter',
    //     },
    // },
    // {
    //     path: '/mine/userInfo', //个人资料
    //     name: 'UserInfo',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/UserInfo.vue'),
    // },
    // {
    //     path: '/mine/vip', //VIP
    //     name: 'Vip',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/Vip.vue'),
    // },
    // {
    //     path: '/mine/vip/details', //VIP
    //     name: 'VipDetails',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/VipDetails.vue'),
    // },
    // {
    //     path: '/mine/record/welfare', //福利中心
    //     name: 'Welfare',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/Welfare.vue'),
    // },
    // {
    //     path: '/mine/finance/wallet', //我的钱包
    //     name: 'Wallet',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Wallet.vue'),
    // },
    // {
    //     path: '/mine/record/transRecord', //交易记录
    //     name: 'TransRecord',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/TransRecord.vue'),
    // },
    // {
    //     path: '/mine/record/betRecord', //投注记录
    //     name: 'BetRecord',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/BetRecord.vue'),
    // },
    // {
    //     path: '/mine/record/giftRecord', //兑奖记录
    //     name: 'GiftRecord',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/GiftRecord.vue'),
    // },
    // {
    //     path: '/mine/mail', //消息中心
    //     name: 'Mail',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/Mail.vue'),
    // },
    // {
    //     path: '/mine/feedback', //意见反馈
    //     name: 'Feedback',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/Feedback.vue'),
    // },
    // {
    //     path: '/mine/finance/deposit', //存款
    //     name: 'Deposit',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Deposit.vue'),
    // },
    // {
    //     path: '/mine/finance/transfer', //转账
    //     name: 'Transfer',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Transfer.vue'),
    // },
    // {
    //     path: '/mine/finance/withdrawal', //取款
    //     name: 'Withdrawal',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Withdrawal.vue'),
    // },
    // {
    //     path: '/help/kh', //开户
    //     name: 'Kh',
    //     component: () => import(/*webpackChunkName:"help"*/ '@/views/help/Kh.vue'),
    // },
    // {
    //     path: '/mine/addBankCard', //添加银行卡
    //     name: 'AddBankCard',
    //     component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/AddBankCard.vue'),
    // },
    // {
    //     path: '/humanAffairs', //人事登记（加入人才库）
    //     name: 'humanAffairs',
    //     component: () => import(/*webpackChunkName:"suer"*/ '../components/newPeople/humanManage/humanAffairs.vue'),
    //     meta: {
    //         requireAuth: true,
    //     },
    // },
];
const router = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

// 验证是否需要登录
router.beforeEach((to, from, next) => {
    //判断路由地址是否包含token
    if (to.query.token) {
        sessionStorage.token = to.query.token;
    }
    if (to.matched.some((r) => r.meta.requireAuth)) {
        if (sessionStorage.getItem('token')) {
            next();
        } else {
            next({
                path: '/entry/login',
                query: {
                    redirect: to.fullPath,
                },
            });
        }
    } else {
        next();
    }
    next();
});
router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router;
