<template>
    <div>
        <div class="style__home_container__e96_d">
            <div class="style__HomeBannerbg__ZhLRs style__isWorldCup__LDyyG">
                <div class="downloadBar__downloadBar__JMTBV" v-if="showAppMsg">
                    <ul></ul>
                    <ul class="downloadBar__content__dWS8u">
                        <li class="downloadBar__close__z0XUU" @click="showAppMsg = false"></li>
                        <li class="downloadBar__logo__wXCCp"
                            :style="'background:url(' + $store.state.systemApp.app_logo + ') 0 0/100% 100% no-repeat'"></li>
                        <li class="downloadBar__text__HopRm">
                            <h4>{{$store.state.systemApp.site_keyword}}APP</h4>
                            <p>真人娱乐，体育投注，电子游艺等尽在一手掌握</p>
                        </li>
                        <li class="downloadBar__downloadBtn__hCEy4" @click="goApp"><span>立即下载</span></li>
                    </ul>
                </div>
                <div class="noticeBar__noticeBarContainer__PVr6w">
                    <div class="noticeBar__noticeBarContent__m7shk">
                        <div class="noticeBar__noticeBar__PSheP">
                            <div class="noticeBar__home_notice__iMyyN"><i>公告 |</i></div>
                            <div class="noticeBar__marqueeWrap__ondbE">
                                <div class="marquee__marquee__yWvTd">
                                    <div class="marquee__content__sXtbg" style="animation-duration: 35s">
                                        <span class="marquee__isWorldCup__eRrBi" v-for="(item, index) in noticeList"
                                            :key="index">{{ item }}></span>
                                    </div>
                                </div>
                            </div>
                            <div class="noticeBar__hotEventsWrap__w4luk">
                                <div class="hotSport__emeraldGreen__OLK_1 hotSport__hotSport__qheVl showHotsportAnimation">
                                    <img src="@/assets/images/static/new/home_hotsport@2x.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--轮播-->
                <div class="home-banner">
                    <div class="banner_bannerContent__2-sug">
                        <div
                            class="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios banner_swiperStyle__3nCtb">
                            <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"></div>
                            <div class="swiper-wrapper">
                                <div class="swiper-slide banner_slideContent__35Z2A" v-for="(item, index) in bannerList"
                                    :key="index" @click="openUrl(item.url)">
                                    <div class="image_imageContainer__2dKjX image_cover__3B5D- banner_imgStyle__-sxwF">
                                        <div>
                                            <img :src="item.src" class="home-img-02" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="userCard__userCardContainer__OpNKS">
                    <div class="userCard__userCard__ba4ct">
                        <div class="userCard__userInfomation__JugTT">
                            <div v-if="!$store.state.userInfo.username" @click="$router.jumpRoute('/entry/login')"
                                class="userCard__notLoginBox__Tk1Hw">
                                <h3>您还未登录</h3>
                                <p>登录/注册后查看</p>
                            </div>
                            <div v-else class="userCard__loginBox__kWJx2">
                                <div class="userCard__nameAndGrade__j0grT">
                                    <p id="nameAndGradeVip">{{ $store.state.userInfo.username }}</p>
                                    <img :src="$store.state.userInfo.vipname" class="" alt="" />
                                </div>
                                <ul class="userCard__balance__A8U3E">
                                    <li class="userCard__yyy__T1zHM">¥</li>
                                    <li class="userCard__money__Ttbpu">{{ $store.state.userInfo.balance }}</li>
                                    <!-- <li>
                                        <button class="userCard__showBalanceToggleBtn__wNWCR">
                                            <img
                                                alt=""
                                                src="/assets/commons/images/wallet/gray_eye.2ef35c4a9319077037d6fb174e88c2f4.webp"
                                            />
                                        </button>
                                    </li> -->
                                </ul>
                            </div>
                            <!-- <div v-else class="userCard_notLoginBox__3v7Uk userCard__notLoginBox__Tk1Hw">
                                <div class="userCard_userGrade__2crn4">
                                    <span class="userCard_username__3WiHs">{{ $store.state.userInfo.username }}</span>
                                    <span
                                        class="userCard_vip0__GaF8h userCard_gradeStyle__dIg6W userCard_gradeStyleNotYb__2v1x3"
                                        :style="'background-image:url(' + configs.api.local_domain + $store.state.userInfo.vipname + ')'"
                                    ></span>
                                </div>
                            </div> -->
                        </div>
                        <ul class="userCard__walletContent__q4LNj">
                            <li class="userCard__walletItem__zVcaI" @click="$router.jumpRoute('/mine/finance/myDeposit')">
                                <img src="@/assets/images/static/new/feature_moneysave@2x.webp"
                                    class="userCard__icon__az8hp" />
                                <p>存款</p>
                            </li>
                            <li class="userCard__walletItem__zVcaI" @click="$router.jumpRoute('/mine/finance/transfer')">
                                <img src="@/assets/images/static/new/feature_moneytransfer@2x.webp"
                                    class="userCard__icon__az8hp" />
                                <p>转账</p>
                            </li>
                            <li class="userCard__walletItem__zVcaI" @click="$router.jumpRoute('/mine/finance/withDraw')">
                                <img src="@/assets/images/static/new/feature_moneydraw@2x.webp"
                                    class="userCard__icon__az8hp" />
                                <p>取款</p>
                            </li>
                            <li class="userCard__walletItem__zVcaI" @click="$router.jumpRoute('/mine/vip/privilege')">
                                <img src="@/assets/images/static/new/feature_vip@2x.webp" class="userCard__icon__az8hp" />
                                <p>VIP</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="venueSection__venueSectionContainer__tbfXQ">
                    <div class="venueSection__venueSectionMain__BnoUR">
                        <ul class="venueSection__cateTab__s864U">
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 0 }"
                                @click="goPoint('gameItem-0', 0)">
                                <img v-if="isActive === 0" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_1_selected_icon@2x.webp" alt="" />
                                <img v-else src="@/assets/images/static/new/tab_1@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_1_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_1@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[0].name }}</span>
                            </li>
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 1 }"
                                @click="goPoint('gameItem-1', 1)">
                                <img v-if="isActive === 1" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_2_selected_icon@2x.webp" />
                                <img v-else src="@/assets/images/static/new/tab_2@2x.webp" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_2_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_2@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[1].name }}</span>
                            </li>
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 2 }"
                                @click="goPoint('gameItem-2', 2)">
                                <img v-if="isActive === 2" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_3_selected_icon@2x.webp" />
                                <img v-else src="@/assets/images/static/new/tab_3@2x.webp" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_3_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_3@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[2].name }}</span>
                            </li>
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 3 }"
                                @click="goPoint('gameItem-3', 3)">
                                <img v-if="isActive === 3" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_4_selected_icon@2x.webp" />
                                <img v-else src="@/assets/images/static/new/tab_4@2x.webp" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_4_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_4@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[3].name }}</span>
                            </li>
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 4 }"
                                @click="goPoint('gameItem-4', 4)">
                                <img v-if="isActive === 4" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_5_selected_icon@2x.webp" />
                                <img v-else src="@/assets/images/static/new/tab_5@2x.webp" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_5_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_5@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[4].name }}</span>
                            </li>
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 5 }"
                                @click="goPoint('gameItem-5', 5)">
                                <img v-if="isActive === 5" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_6_selected_icon@2x.webp" />
                                <img v-else src="@/assets/images/static/new/tab_6@2x.webp" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_6_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_6@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[5].name }}</span>
                            </li>
                            <li class="venueSection__tabItem__FfBGD" :class="{ venueSection__active__fK5Ot: isActive == 6 }"
                                @click="goPoint('gameItem-6', 6)">
                                <img v-if="isActive === 6" class="venueSection__activeImg__M4kbI"
                                    src="@/assets/images/static/new/tab_7_selected_icon@2x.webp" />
                                <img v-else src="@/assets/images/static/new/tab_7@2x.webp" />
                                <img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_7_selected_icon@2x.webp" width="0.64rem"
                                    height="0.64rem" alt="" /><img class="venueSection__unActiveImg__TcNH5"
                                    src="@/assets/images/static/new/tab_7@2x.webp" width="0.64rem" height="0.64rem"
                                    alt="" /><span>{{ gameTypeList[6].name }}</span>
                            </li>
                        </ul>
                        <img class="venueSection__venueTabBottomLine__IpDYf"
                            src="@/assets/images/static/new/event_tab_bottomline@2x.webp" />
                    </div>
                    <div class="venueSection__venueWrap__XiXpQ" id="contentVenue" v-if="gameList">
                        <ul class="venueSection__venuesList__zkRaq">
                            <li :id="item.id" class="venueSection__venuesItem__nwjne" v-for="(item, index) in gameList"
                                :key="index" @click="getGameUrl(item)">
                                <img v-if="item.id" alt="" src="@/assets/images/static/new/card_tag1@2x.webp"
                                    class="venueSection__cardLabel__jDqA4" />
                                <img :src="item.mobile_img" class="venueSection__cardBg__Z7FLc" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--底部-->
        <nav-footer></nav-footer>
    </div>
</template>
<script>
export default {
    name: 'HomeView',
    data() {
        return {
            bannerList: [],
            noticeList: [],
            gameDetail: null,
            //游戏列表concise电子/gaming电竞/joker棋牌/lottery彩票/realbet真人/sport体育
            gameList: [],
            isActive: 0,
            token: sessionStorage.token,
            isShowTips: false,
            showAppMsg: true,
            gameTypeList: [],
            domTop: 0,
            isChange: false,
        };
    },
    created() {
        // this.$request.getIndexmsg().then((res) => {
        //     console.log(res);
        //     let data = res.data;
        //     if (data.code === 200) {
        //         console.log();
        //     }
        // });
        //获取首页banner
        this.getBannerList();
        //获取滚动公告栏内容
        this.getHomeNotice();
        //判断、获取所有游戏
        this.gameTypeList = this.$store.state.gameTypeList;
        if (this.gameTypeList[0].list.length <= 0) {
            this.gameTypeList.map((item) => {
                this.$store.dispatch('getGameTypeList', item.type);
            });
        }
        if (this.token) {
            //获取用户信息
            this.$store.dispatch('getUserInfo');
        }
    },
    watch: {
        gameTypeList: {
            handler: function () {
                if (this.gameTypeList.length > 0) {
                    let arr = [];
                    this.gameTypeList.map((item, index) => {
                        if (item.headerList) {
                            item.headerList.map((kItem, kIndex) => {
                                if (kIndex === 0) {
                                    kItem.id = 'gameItem-' + index;
                                }
                                arr.push(kItem);
                            });
                        }
                    });
                    this.gameList = arr;
                    this.domTop = document.getElementById('contentVenue').getBoundingClientRect().top;
                }
            },
            deep: true,
        },
    },
    mounted() {
        //向页面添加滚动事件
        window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (!this.isChange && this.isActive >= 0 && this.isActive < this.gameTypeList.length) {
                let scrollTop = document.getElementById('gameItem-' + this.isActive).getBoundingClientRect().top;
                let nextTop = 0;
                if (this.isActive < this.gameTypeList.length - 1 && document.getElementById('gameItem-' + (this.isActive + 1))) {
                    nextTop = document.getElementById('gameItem-' + (this.isActive + 1)).getBoundingClientRect().top;
                }
                let prevTop = 0;
                if (this.isActive > 0 && document.getElementById('gameItem-' + (this.isActive - 1))) {
                    prevTop = document.getElementById('gameItem-' + (this.isActive - 1)).getBoundingClientRect().top;
                }
                if (scrollTop < 0 && nextTop < this.domTop + 10 && this.isActive < this.gameTypeList.length - 1) {
                    this.isActive++;
                } else if (prevTop > 0 || this.domTop + 10 < scrollTop) {
                    this.isActive--;
                }
            }
        },
        goPoint(key, i) {
            this.isChange = true;
            this.isActive = i;
            if (document.getElementById(key)) {
                document.getElementById(key).scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
            setTimeout(() => {
                this.isChange = false;
            }, 800);
            // this.$refs[key].scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        bannerLeft() {
            if (this.bannerIndex > 0) {
                this.bannerIndex--;
            } else {
                this.bannerIndex = this.bannerList.length - 1;
            }
        },
        bannerRight() {
            if (this.bannerIndex < this.bannerList.length - 1) {
                this.bannerIndex++;
            } else {
                this.bannerIndex = 0;
            }
        },
        changeDownloadIndex(i) {
            this.downloadIndex = i;
            this.changeDownloadIng = true;
            setTimeout(() => {
                this.changeDownloadIng = false;
            }, 500);
        },
        //获取首页banner
        getBannerList() {
            this.$request.getBannerList({ type: 2 }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.bannerList = data.data;
                    this.$nextTick(() => {
                        let swiperSponsor = new this.$Swiper('.swiper-container', {
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                            },
                            loop: true,
                        });
                    });
                }
            });
        },
        //获取滚动公告栏内容
        getHomeNotice() {
            this.$request.getHomeNotice().then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.noticeList = data.data;
                }
            });
        },
        //获取游戏链接
        getGameUrl(item) {
            //判断是否登录
            if (this.$utils.isLogin()) {
                this.$request
                    .getGameUrl({ plat_name: item.platform_name, game_type: item.game_code, game_code: item.category_id, is_mobile_url: 1 })
                    .then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            this.$store.commit('setGameUrl', { game_code: item.game_code, url: data.data.url });
                            window.open(data.data.url, '_self');
                        }
                    });
            } else {
                this.$router.jumpRoute('/entry/login', {
                    redirect: this.$route.path,
                });
            }
        },
        asyncOpenWindow(res) {
            let newWindow = null;
            const timeOut = setTimeout(() => {
                try {
                    if (navigator.userAgent.indexOf('Chrome') > -1) {
                        newWindow = window.open('', '', 'location=no,menubar=no');
                    }
                } catch (e) {
                    console.log(e);
                }
            }, 4500);
            clearTimeout(timeOut);
            let data = res.data;
            if (data.code === 200) {
                if (newWindow) {
                    newWindow.location.href = data.data.url;
                } else {
                    window.open(data.data.url, '_blank');
                }
            }
        },
        //关闭弹窗
        closeTips() {
            this.showLoginTips = false;
        },
        //中心钱包提示显示
        showTips() {
            this.isShowTips = true;
            setTimeout(() => {
                this.isShowTips = false;
            }, 3000);
        },
        //立即下载
        goApp() {
            this.$router.push('/download');
        },
        openUrl(url) {
            if (url) {
                window.open(url, '_blank');
            }
        }
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/new/Home.478e5046.css';

.downloadBar__downloadBar__JMTBV ul .downloadBar__close__z0XUU {
    background: url('@/assets/images/static/new/home_download_close@2x.webp') 0.3rem center/.42rem 0.42rem no-repeat;
}

.downloadBar__downloadBar__JMTBV ul .downloadBar__downloadBtn__hCEy4 {
    background-image: linear-gradient(to right, #90f0ff, #265cf6);
}

.downloadBar__downloadBar__JMTBV ul {
    background-color: #ffffff;
}

.downloadBar__downloadBar__JMTBV ul .downloadBar__downloadBtn__hCEy4 span {
    color: #ffffff;
}

.style__home_container__e96_d .style__HomeBannerbg__ZhLRs {
    background-image: url('@/assets/images/static/new/top_bg@2x.webp');
    background-position: top, bottom;
    background-repeat: no-repeat;
    background-size: 7.5rem 4.8rem;
    box-shadow: 0 13px 28px rgba(128, 161, 189, 0.379);
    overflow-y: hidden;
}

.noticeBar__noticeBar__PSheP .noticeBar__home_notice__iMyyN:before {
    background-image: url('@/assets/images/static/new/home_notice@2x.webp');
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD:first-child.venueSection__active__fK5Ot {
    background: url('@/assets/images/static/new/tab_selected@2x.webp');
    background-position: top 0.08rem center;
    background-repeat: no-repeat;
    background-size: 1.16rem 1rem;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD.venueSection__active__fK5Ot>span {
    background-image: url('@/assets/images/static/new/tab_selected@2x.webp');
    background-position: top 0 center;
    background-repeat: no-repeat;
    background-size: 1.16rem 0.64rem;
    margin-top: 0.18rem;
}

.home-banner {
    background: linear-gradient(180deg, #e8edfb, #e8edfb 66.09%, #fdfeff 77.57%, #fff);
    left: 0;
    padding: 0 0.28rem 0.04rem;
    position: relative;
}

.swiper-container {
    background: linear-gradient(180deg, #fff, #f6faff);
    border: 1px solid #fff;
    border-radius: 0.2rem;
    box-shadow: 0 4px 4px rgba(128, 161, 189, 0.3);
    height: 2.92rem;
    overflow: hidden;
}

.userCard__userCardContainer__OpNKS {
    background: url('@/assets/images/static/new/feature_bg@2x.webp');
    background-size: 100% 100%;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD:first-child.venueSection__active__fK5Ot {
    background: url('@/assets/images/static/new/tab_selected_bg1@2x.webp');
    background-position: top 0.08rem center;
    background-repeat: no-repeat;
    background-size: 1.16rem 1rem;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD:nth-child(2).venueSection__active__fK5Ot {
    background: url('@/assets/images/static/new/tab_selected_bg2@2x.webp');
    background-position: top 0.08rem center;
    background-repeat: no-repeat;
    background-size: 1.16rem 1rem;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD:nth-child(3).venueSection__active__fK5Ot {
    background: url('@/assets/images/static/new/tab_selected_bg3@2x.webp');
    background-position: top 0.08rem center;
    background-repeat: no-repeat;
    background-size: 1.16rem 1rem;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD:nth-child(4).venueSection__active__fK5Ot {
    background: url('@/assets/images/static/new/tab_selected_bg4@2x.webp');
    background-position: top 0.08rem center;
    background-repeat: no-repeat;
    background-size: 1.16rem 1rem;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD:nth-child(5).venueSection__active__fK5Ot {
    background: url('@/assets/images/static/new/tab_selected_bg5@2x.webp');
    background-position: top 0.08rem center;
    background-repeat: no-repeat;
    background-size: 1.16rem 1rem;
}

.home-img-02 {
    width: 100%;
}

.marquee__content__sXtbg span {
    font-size: 0.26rem;
    line-height: 0.3rem;
}

.venueSection__cateTab__s864U .venueSection__tabItem__FfBGD.venueSection__active__fK5Ot>span {
    color: #ffffff;
    font-size: 0.32rem;
}
</style>
