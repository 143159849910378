import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueLazyload from 'vue-lazyload';
import VueClipboard from 'vue-clipboard2';

// // 引入axios
// import axios from './http';
// Vue.prototype.$axios = axios;
// 注册封装的请求方法
import * as request from '@/request/api/index.js';
Vue.prototype.$request = request.default;

// 引入基本配置
import config from '@/utils/index.js';
Vue.prototype.configs = config;

import utils from '@/utils/common.js';
Vue.prototype.$utils = utils;

// 自定义引入公共组件
import Components from '@/utils/components';
Vue.use(Components);

//图片懒加载
Vue.use(VueLazyload);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('@/assets/images/static/error.png'),
    loading: require('@/assets/images/static/loading.png'),
    attempt: 1,
});

// // 引入事件bus
// import hub from './hub';
// Vue.prototype.$hub = hub;

// // md5
// import md5 from 'js-md5';
// Vue.prototype.$md5 = md5;

// //引入复制插件
// import clipboard from 'clipboard';
// Vue.prototype.clipboard = clipboard;
Vue.use(VueClipboard);

import rem from './rem';

// 引入vant 组件
import { Toast } from 'vant';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.prototype.$Toast = Toast;

// // 引入viewUI
// import { Message } from 'view-design';
// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
// Vue.use(ViewUI);
// Message.config({
//     top: 130,
//     duration: 3,
// });
// Vue.prototype.$Message = Message;

// 注冊公共信息，公共方法
// import utils from '@/utils/index';
// Vue.prototype.pb_method = utils;

// 引入swiper
import Swiper from 'swiper';
// import 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';
Vue.prototype.$Swiper = Swiper;
//引入滑块验证码
import SliderVerify from 'slider-verify-v2';
import 'slider-verify-v2/lib/SliderVerify.css';
Vue.use(SliderVerify);

import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);
// 时间解析，格式化
// import * as filters from '@/utils/filters.js';
// Vue.prototype.pb_date = filters;

// Object.keys(filters).forEach((name) => {
//     Vue.filter(name, filters[name]);
// });

//layout页面布局
// import DefaultLayout from './views/layout/Default.vue';
// import LoginLayout from './views/layout/Login.vue';
// import NoFooterLayout from './views/layout/NoFooter.vue';
// Vue.component('default-layout', DefaultLayout);
// Vue.component('login-layout', LoginLayout);
// Vue.component('noFooter-layout', NoFooterLayout);

// 滚动条置顶
Vue.prototype.scrollBottom = function () {
    // 滚动条置顶
    this.$nextTick(() => {
        window.scrollTo(0, 0);
    });
    // document.documentElement.scrollTop = document.body.scrollTop = 0;
};

// // 引入echarts
// import echarts from 'echarts';
// Vue.prototype.$echarts = echarts;

//定义全局过滤器
//金额过滤(保留两位小数)
Vue.filter('numToFloat', function (value) {
    return parseFloat(value).toFixed(2);
});
//金额过滤(整数)
Vue.filter('numToInt', function (value) {
    return parseInt(value);
});
// 千位符
Vue.filter('changeFormat', function (num) {
    if ((num + '').trim() == '') {
        return '';
    }
    if (String(num).indexOf(',') == -1) {
        num = parseFloat(num);
        num = num.toFixed(2);
        num = parseFloat(num);
        num = num.toLocaleString();
    } else {
        num = num.replace(/,/gi, '');
        num = parseFloat(num);
    }
    return num;
});

Vue.prototype.resetSetItem = function (key, newVal) {
    // 创建一个StorageEvent事件
    let newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function (k, val) {
            sessionStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('sessionItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        },
    };
    return storage.setItem(key, newVal);
};

let temptStop;
//弹出框禁止滑动
Vue.prototype.stopScroll = function (isNeed) {
    // 获取滚动位置
    if (isNeed) {
        temptStop = document.documentElement.scrollTop || document.body.scrollTop;
    }
    document.documentElement.classList.add('riven-overflow-hidden');
    document.body.classList.add('riven-overflow-hidden');
    document.getElementById('app').classList.add('riven-overflow-hidden');
};

//弹出框可以滑动
Vue.prototype.canScroll = function (isNeed) {
    document.documentElement.classList.remove('riven-overflow-hidden');
    document.body.classList.remove('riven-overflow-hidden');
    document.getElementById('app').classList.remove('riven-overflow-hidden');
    // 回滾
    if (isNeed) {
        document.documentElement.scrollTop = document.body.scrollTop = temptStop;
    }
};

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
