<template>
    <div id="app">
        <Loading v-show="LOADING"></Loading>
        <!-- <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
        </nav> -->
        <!-- <component :is="layout">
            <router-view v-if="isRouterAlive"></router-view>
        </component> -->
        <router-view v-if="isRouterAlive"></router-view>
        <!--系统维护提示-->
        <div class="maintain" v-if="$store.state.systemApp.site_state == 2">
            <p>{{ $store.state.systemApp.repair_tips }}</p>
        </div>
        <!--自定义组件、内容-->
        <div class="captchaButton" v-if="showSlider" v-click-outside="hideSlider">
            <SliderVerify
                :width="300"
                :height="150"
                :img-url="imgUrl"
                :s-text="sText"
                :e-text="eText"
                :is-border="isBorder"
                :is-close-btn="isCloseBtn"
                :is-reload-btn="isReloadBtn"
                :is-parent-node="isParentNode"
                :is-show-tip="isShowTip"
                :is-show-self="isShowSelf"
                :is-verify-show="isVerifyShow"
                @reload="emitChange('reload')"
                @show="emitChange('show')"
                @hide="emitChange('hide')"
                @close="emitChange('close')"
                @success="emitChange('success')"
                @fail="emitChange('fail')"
            ></SliderVerify>
        </div>
        <!--自定义组件、内容-->
    </div>
</template>

<script>
// import store from './store';
import bus from './bus.js';
import {mapState} from 'vuex';
import Loading from '@/views/Loading.vue';
//初始化凯格行为验证码
// kg.captcha({
//     // 绑定弹窗按钮
//     button: '#captchaButton',
//     // 验证成功事务处理
//     success: function (e) {
//         // 验证成功，直接提交表单
//         // form1.submit();
//         // console.log(store);
//         // store.commit('setSystemApp', true);
//         bus.$emit('send', true);
//         // setTimeout(() => {
//         //     kg.reload(kg.param);
//         // }, 300);
//     },
//     // 验证失败事务处理
//     failure: function (e) {
//         // console.log(e);
//         // store.commit('setSystemApp', false);
//         bus.$emit('send', false);
//     },
//     // 点击刷新按钮时触发
//     refresh: function (e) {
//         // console.log(e);
//         // store.commit('setSystemApp', false);
//         bus.$emit('send', false);
//     },
//
// });
export default {
    name: 'App',
    components: {Loading},
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            //default_layout: 'default', //设置layout
            isClickOutside: false,
            isRouterAlive: true,
            //验证码
            showSlider: false,
            isShowSelf: true,
            // imgUrl: apiUrl + '/img/SliderVerify-'+ random+'.png',
            imgUrl: 'https://picsum.photos/300/150?random=1',
            width: 300,
            height: 150,
            sText: '向右滑动滑块填充拼图',
            eText: '验证通过',
            isBorder: true,
            isCloseBtn: true,
            isReloadBtn: true,
            isParentNode: false,
            isShowTip: true,
            isVerifyShow: false,
        };
    },
    created() {
        if (this.$route.query.token) {
            sessionStorage.token = this.$route.query.token;
        }
        //判断、获取客服链接
        let servicerUrl = this.$store.state.servicerUrl;
        if (!servicerUrl) {
            this.$store.dispatch('getServicerUrl');
        }
        //获取余额
        if (sessionStorage.token) {
            this.$store.dispatch('getBalance');
            let userInfo = this.$store.state.userInfo;
            if (!userInfo.joinday) {
                //获取用户信息
                this.$store.dispatch('getUserInfo');
            }
        }
        //获取系统信息
        this.getSystemApp();
    },
    mounted() {
        //验证码事件监听
        // bus.$on('sendVerify', () => {
        //     kg.reload(kg.param);
        //     this.$refs.captchaButton.dispatchEvent(new MouseEvent('click'));
        // });
        bus.$on('send', (data) => {
            console.log(data);
            let random = Math.floor(Math.random() * 100);
            this.imgUrl = 'https://picsum.photos/300/150?random=' + random;
            if (data) {
                this.showSlider = false;
                this.isVerifyShow = false;
            } else {
                this.isVerifyShow = true;
                this.showSlider = true;
            }
        });
    },
    computed: {
        ...mapState(['LOADING']),
        // layout() {
        //     return (this.$route.meta.layout || this.default_layout) + '-layout';
        // },
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
        getSystemApp() {
            this.$request.getSystemApp().then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    if (data.data.title) {
                        document.title = data.data.title;
                    }
                    this.$store.commit('setSystemApp', data.data);
                }
            });
        },
        emitChange(type) {
            // 处理验证成功后的逻辑
            if (type === 'success') {
                this.eText = '验证成功';
                this.sText = '验证成功';
                // store.commit('setSystemApp', true);
                setTimeout(() => {
                    bus.$emit('send', true);
                }, 1000);
            }
            // 处理验证失败后的逻辑
            if (type === 'fail') {
                this.eText = '验证失败，请重试';
                let random = Math.floor(Math.random() * 100);
                this.imgUrl = 'https://picsum.photos/300/150?random=' + random;
                bus.$emit('send', false);
            }
            // 处理验证刷新后的逻辑
            if (type === 'reload') {
                this.eText = '请向右滑动滑块填充拼图';
                this.sText = '向右滑动滑块填充拼图';
                let random = Math.floor(Math.random() * 100);
                this.imgUrl = 'https://picsum.photos/300/150?random=' + random;
                // store.commit('setSystemApp', false);
                bus.$emit('send', false);
            }
            // 处理验证关闭后的逻辑
            if (type === 'close') {
                this.showSlider = false;
                this.isVerifyShow = false;
            }
        },
        hideSlider() {
            this.showSlider = false;
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
@import '@/assets/css/static/ec78c9d14578602455d9.css';
#app {
    height: 100%;
}
.maintain {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding: 100px;
    color: #ffffff;
    font-size: 26px;
    background: black;
    z-index: 999999;
}
//相对居中
.captchaButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 999999;
}
</style>
