<template>
    <div class="style__footer_bg__hUV9D" id="app-footer">
        <ul>
            <li
                data-analytics="button"
                :data-label="item.name"
                @click="$router.jumpRoute(item.path)"
                v-for="(item, index) in footerList"
                :key="index"
            >
                <div :class="{ style__shadowCircle__jw2yJ: index == 2 }"></div>
                <div class="centerItem">
                    <i></i>
                    <img v-if="item.path == pagePath" :src="item.imgAct" width="0.24rem" height="0.24rem" />
                    <img v-else :src="item.img" width="0.24rem" height="0.24rem" />
                </div>
                <span :class="{ style__active__BbnOJ: item.path == pagePath }">{{ item.name }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            footerList: [
                {
                    name: '优惠',
                    path: '/activity/list',
                    img: require('@/assets/images/static/new/tabbar_1@2x.webp'),
                    imgAct: require('@/assets/images/static/new/tabbar_1_select@2x.webp'),
                },
                {
                    name: '客服',
                    path: '/customer/main',
                    img: require('@/assets/images/static/new/tabbar_6@2x.webp'),
                    imgAct: require('@/assets/images/static/new/tabbar_6_select@2x.webp'),
                },
                {
                    name: '',
                    path: '/',
                    img: require('@/assets/images/static/new/tabbar_3@2x.png'),
                    imgAct: require('@/assets/images/static/new/tabbar_3_select@2x.png'),
                },
                {
                    name: '赞助',
                    path: '/sponsor',
                    img: require('@/assets/images/static/new/tabbar_4@2x.webp'),
                    imgAct: require('@/assets/images/static/new/tabbar_4_select@2x.webp'),
                },
                {
                    name: '我的',
                    path: '/mine',
                    img: require('@/assets/images/static/new/tabbar_5@2x.webp'),
                    imgAct: require('@/assets/images/static/new/tabbar_5_select@2x.webp'),
                },
            ],
            pagePath: this.$route.path,
        };
    },
    created() {
        //console.log(this.$route.path);
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/new/emeraldGreen_Footer.e6ca6068.css';
.style__footer_bg__hUV9D,
.style__footer_bg_hidden__D2CEJ {
    background-image: url('@/assets/images/static/new/tabbar_bg@2x.webp');
}
</style>
